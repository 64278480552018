<template>
  <div class="page-cargo">
    <div class="breadcrumb-wrapper">
      <div v-if="cargo">
        <h2 class="content-header-title float-left pr-1 mb-0">
          Carico {{ cargo.c_carico }}
        </h2>
        <b-breadcrumb
          class="mb-1"
        >
          <b-breadcrumb-item to="/">
            <feather-icon
              icon="HomeIcon"
              size="16"
              class="align-text-top"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item
            to="/carichi"
            class="d-md-inline-block d-sm-none d-none"
          >
            <span>I tuoi carichi</span>
          </b-breadcrumb-item>
          <b-breadcrumb-item
            active
            class="d-md-inline-block d-sm-none d-none"
          >
            <span>Carico {{ cargo.c_carico }}</span>
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <b-row>
      <b-col
        cols="12"
        xl="4"
        lg="4"
        md="12"
      >
        <b-overlay
          :show="showOverlay"
          variant="white"
        >
          <b-card class="cargo-info-box">

            <b-row v-if="cargo && parseInt(cargo.fg_stato_piano) > 5">
              <!-- Left col -->
              <b-col
                cols="12"
                xl="12"
                class="d-flex justify-content-between flex-column"
              >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                  <div class="d-flex flex-column w-100">
                    <div class="mb-1">
                      <h1 class="mb-0">
                        CARICO {{ cargo.c_carico }}
                      </h1>
                      <div><span class="text-uppercase font-weight-bold">{{ cargo.ds_piano_carico }}</span></div>
                      <div><span class="text-important text-uppercase font-weight-bold">{{ cargo.ds_vettore1 }}</span></div>
                    </div>
                    <div class="barcode mb-1">
                      <vue-barcode
                        :value="cargo.c_carico"
                        tag="svg"
                        :options="{ displayValue: false, width: 3, margin: 0, font: 'Roboto' }"
                      />
                    </div>
                    <table class="mt-2 mt-xl-0 w-100">
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="CalendarIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Data partenza</span>
                        </th>
                        <td class="pb-50">
                          {{ new Intl.DateTimeFormat('en-GB').format(Date.parse(cargo.dt_partenza)) }}
                        </td>
                      </tr>
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="MapIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">KM Stimati</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                          {{ parseInt(cargo.n_km_stimati) }} Km
                        </td>
                      </tr>
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="MapPinIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Numero Consegne</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                          {{ cargo.n_consegne }}
                        </td>
                      </tr>
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="PackageIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Numero colli</span>
                        </th>
                        <td class="pb-50">
                          {{ cargo.n_colli }}
                        </td>
                      </tr>
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="BoxIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Volume</span>
                        </th>
                        <td>
                          {{ cargo.n_volume }} m<sup>3</sup>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <feather-icon
                            icon="PhoneIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Autista</span>
                        </th>
                        <td>
                          {{ cargo.trasportatore }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <b-overlay
                  :show="showOverlayDDT"
                  variant="white"
                  spinner-small
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="d-flex align-items-center justify-content-start justify-content-start flex-wrap"
                    >
                      <div
                        v-if="parseInt(cargo.n_ddt_in_arxivar) === ddt.length"
                        class="my-1"
                      >
                        <span>
                          <small>
                            <span
                              class="px-50 text-white rounded-circle mr-25"
                              :class="parseInt(cargo.n_ddt_scaricati) === parseInt(cargo.n_ddt_in_arxivar) ? 'bg-success' : 'bg-warning'"
                            />

                            <strong>DDT SCARICATI:</strong> {{
                              cargo.n_ddt_scaricati
                            }} su {{ cargo.n_ddt_in_arxivar }}
                          </small>
                        </span>
                      </div>
                      <div
                        v-else
                        class="my-1"
                      >
                        <span>
                          <small>
                            <span
                              class="px-50 text-white rounded-circle bg-danger mr-25"
                            />
                            <span class="text-danger">
                              <strong>ANOMALIA: DDT in Arxivar </strong> {{
                                parseInt(cargo.n_ddt_in_arxivar)
                              }} su {{ ddt.length }}
                            </span>
                          </small>
                        </span>
                      </div>

                      <span class="ml-50">
                        <feather-icon
                          class="cursor-pointer"
                          icon="RefreshCcwIcon"
                          @click="loadCargo"
                        />
                      </span>

                      <div
                        v-if="parseInt(cargo.n_ddt_in_errore)"
                        class="mt-1 pr-1 w-100"
                      >
                        <span class="text-danger">
                          <small>
                            <feather-icon
                              class="mr-25 text-danger"
                              icon="AlertTriangleIcon"
                              size="13"
                            />
                            <strong>DDT IN ERRORE:</strong> {{ cargo.n_ddt_in_errore }}
                          </small>
                        </span>
                      </div>
                      <div
                        v-if="(parseInt(cargo.n_ddt_in_arxivar) === ddt.length)"
                        class="w-100"
                      >
                        <!--
                        <div
                            v-if="(parseInt(cargo.fg_tipo_piano) !== 5)"
                        >-->
                        <div
                          v-if="(parseInt(cargo.fg_tipo_piano))"
                        >
                          <div
                            v-if="(parseInt(cargo.n_ddt_in_arxivar) !== parseInt(cargo.n_ddt_scaricati)) "
                            class="mt-2"
                          >
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="success"
                              class="mr-75"
                              block
                              @click="ubsignDownloadDDT"
                            >
                              <feather-icon
                                icon="DownloadIcon"
                                class="mr-50"
                                size="16"
                              />
                              <span class="align-middle">SCARICA I DDT</span>
                            </b-button>
                          </div>
                          <div v-else-if="parseInt(cargo.n_ddt_in_arxivar) !== 0">
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              class="mr-75"
                              block
                              @click="openApp(cargo.c_carico)"
                            >
                              <feather-icon
                                icon="FileIcon"
                                class="mr-50"
                                size="16"
                              />
                              <span class="align-middle">MOSTRA DDT SCARICATI</span>
                            </b-button>
                          </div>
                        </div>
                        <div
                          v-else
                          class="mt-2 mb-1"
                        >
                          <strong>GROUPAGE: </strong> Effettuare il download dei DDT solo per le consegne in gestione.
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-overlay>

                <!--
                <div
                  class="pb-0 mb-1 mt-50"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    block
                  >
                    <feather-icon
                      icon="TruckIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span class="align-middle">CHIUDI CARICO</span>
                  </b-button>
                </div>
                -->

                <div class="pb-0 mt-50">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    :to="{ name: 'cargo-list'}"
                    block
                  >
                    INDIETRO
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        xl="8"
        lg="8"
        md="12"
      >
        <b-overlay
          :show="showOverlayList"
          variant="white"
        >
          <b-card class="cargo-info-box">
            <b-row v-if="cargo && deliveries && parseInt(cargo.fg_stato_piano) > 5">
              <b-col
                cols="12"
                xl="12"
                class="d-flex justify-content-between flex-column"
              >
                <div class="pb-2">
                  <div class="pb-2">
                    <h2 class="pb-1">
                      Elenco consegne
                    </h2>
                    <p>
                      <feather-icon
                        class="text-body"
                        icon="InfoIcon"
                        size="21"
                      />
                      <small>
                        Quando il badge degli ordini è di questo colore
                        <span class="mr-50">
                          <feather-icon
                            badge="+1"
                            badge-classes="bg-warning"
                            class="text-body"
                            icon="ShoppingCartIcon"
                          />
                        </span>
                        significa che sono stati <strong>aggiunti degli ordini alla consegna</strong>.
                        Puoi cliccare sull'icona per visualizzare gli ordini nel dettaglio.
                      </small>
                    </p>
                  </div>
                  <div class="d-flex justify-content-start align-items-center flex-wrap">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      :to="{ name: 'cargo-list'}"
                      class="mr-1"
                    >
                      INDIETRO
                    </b-button>

                    <b-form-checkbox
                      v-model="showClosedDeliveriesLocal"
                      :value="true"
                      :unchecked-value="false"
                      name="show-closed-deliveries"
                      switch
                      inline
                      :disabled="showOverlay"
                      class="mt-1 mt-sm-0"

                      @change="setShowClosedDeliveryState(showClosedDeliveriesLocal)"
                    >
                      Mostra le consegne chiuse
                    </b-form-checkbox>
                  </div>
                </div>

                <!-- Elenco consegne -->
                <b-form-checkbox-group
                  id="selected-deliveries"
                  v-model="selectedDeliveries"
                  name="selected-deliveries"
                >
                  <b-list-group v-if="cargo">
                    <transition-group
                      type="transition"
                      name="flip-list"
                    >
                      <b-list-group-item
                        v-for="listItem in deliveries"
                        v-show="(parseInt(listItem.fg_consegnato) === 0 || showClosedDeliveriesLocal)"
                        :key="listItem.n_sequenza"
                        class="w-100 delivery"
                        :class="parseInt(listItem.TotImportanza) ? 'important' : ''"
                        tag="li"
                      >
                        <b-row class="no-gutters">
                          <b-col
                            cols="2"
                            sm="1"
                            md="1"
                            class="d-flex align-items-center justify-content-center p-75"
                          >
                            <div class="no-gutters d-flex flex-wrap  align-items-center justify-content-center w-100">
                              <div
                                class="align-items-center justify-content-center flex-column"
                              >
                                <div class="p-0 delivery-checkbox-wrapper">
                                  <h3>
                                    <strong>
                                      <b-badge
                                        variant="important"
                                        pill
                                        class="badge-round align-self-center mr-25"
                                      >
                                        {{ listItem.n_sequenza }}
                                      </b-badge>
                                    </strong>
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col
                            cols="10"
                            sm="6"
                            md="6"
                            xl="7"
                            class="d-flex align-content-center justify-content-left flex-wrap py-2"
                          >
                            <div class="primary w-100">
                              <div
                                v-if="parseInt(listItem.fg_consegnato) || parseInt(listItem.TotImportanza)"
                                class="mb-1"
                              >
                                <b-badge
                                  v-if="parseInt(listItem.TotImportanza)"
                                  variant="primary"
                                  pill
                                  class="badge-round align-self-center mr-25"
                                >
                                  Consegnata
                                </b-badge>
                                <b-badge
                                  v-if="parseInt(listItem.fg_consegnato)"
                                  variant="primary"
                                  pill
                                  class="badge-round align-self-center delivery-status-badge mr-25"
                                  :class="'closed-'+listItem.fg_consegnato+' '+listItem.slug_tipo_scarico"
                                >
                                  Consegnata
                                </b-badge>
                              </div>
                              <h5>
                                <strong>
                                  <!--<b-badge
                                  variant="primary"
                                  pill
                                  class="badge-round  align-self-center mr-25"
                                >
                                  {{ listItem.n_sequenza }}
                                </b-badge>-->
                                  {{ `[${listItem.c_nome_consegna}] ${listItem.ds_destinazione}` }}
                                </strong>
                              </h5>
                            </div>
                            <div
                              v-if="listItem.dt_consegna_prevista_proposta"
                              class="date mt-25 w-100 text-primary"
                            >
                              <span
                                v-b-tooltip.hover.v-dark
                                title="Data di consegna prevista"
                                class="text-important"
                              >
                                <feather-icon
                                  variant="important"
                                  icon="CalendarIcon"
                                />
                                <small>
                                  {{
                                    new
                                      Intl.DateTimeFormat('en-GB').format(Date.parse(listItem.dt_consegna_prevista_proposta))
                                  }}
                                </small>
                              </span>
                            </div>
                            <div class="address mt-25 w-100">
                              <small>
                                {{ listItem.indirizzo_consegna }}<br>{{
                                  `${listItem.citta_consegna}
                                ${listItem.n_cap_consegna} (${listItem.c_provincia_consegna})`
                                }}
                              </small>
                            </div>
                            <b-row class="no-gutters w-100">
                              <b-col
                                cols="12"
                                class="d-flex align-items-center justify-content-start justify-content-start flex-wrap pt-1"
                              >
                                <div
                                  v-b-tooltip.hover.v-dark
                                  title="Numero colli"
                                  class="pr-1"
                                >
                                  <small>{{ parseInt(listItem.n_colli) }}</small>
                                  <feather-icon
                                    class="text-body"
                                    icon="PackageIcon"
                                    size="21"
                                  />
                                </div>

                                <div
                                  v-b-tooltip.hover.v-dark
                                  title="Volume"
                                  class="pb-50 pr-1"
                                >
                                  <small>{{ listItem.n_volume + 'm³' }}</small>
                                  <feather-icon
                                    class="text-body"
                                    icon="BoxIcon"
                                    size="21"
                                  />
                                </div>
                                <div
                                  v-b-tooltip.hover.v-dark
                                  title="Apri mappa"
                                  class="pb-50 pr-1"
                                >
                                  <a
                                    :href="'https://www.google.com/maps/dir/?api=1&destination='+listItem.n_latitudine + ',' + listItem.n_longitudine"
                                    target="_blank"
                                  >
                                    <feather-icon
                                      class="text-body"
                                      icon="MapPinIcon"
                                      size="21"
                                    />
                                  </a>
                                </div>
                                <div
                                  v-b-tooltip.hover.v-dark
                                  v-b-modal.modal-contacts
                                  title="Contatti utili"
                                  class="pb-50 pr-1"
                                  @click="getDeliveryData(listItem.n_consegna)"
                                >
                                  <feather-icon
                                    class="text-body"
                                    icon="PhoneIcon"
                                    size="21"
                                  />
                                </div>
                                <div
                                  v-b-tooltip.hover.v-dark
                                  v-b-modal.modal-orders
                                  title="Visualizza ordini"
                                  class="pb-50 pr-1"
                                  @click="getCargoDeliveryOrders(listItem.n_consegna)"
                                >
                                  <feather-icon
                                    :badge="parseInt(listItem.fg_minicommessa) ? ((parseInt(listItem.n_ordini-listItem.fg_minicommessa) ? listItem.n_ordini-listItem.fg_minicommessa : '') + '+' + listItem.fg_minicommessa) : listItem.n_ordini"
                                    :badge-classes="parseInt(listItem.fg_minicommessa) ? 'bg-warning' : 'bg-success'"
                                    class="text-body"
                                    icon="ShoppingCartIcon"
                                    size="21"
                                  />
                                </div>

                                <div v-if="control_pin_enabled && listItem.pin_controllo_richiesto" class="mt-50 w-100">
                                  <b-badge
                                    variant="light-danger"
                                    pill
                                    class="badge-round align-self-center mr-50"
                                  >
                                    <feather-icon
                                      icon="ShieldIcon"
                                      size="24"
                                      class="mr-25"
                                    />
                                    Pin di controllo richiesto
                                  </b-badge>
                                </div>

                              </b-col>

                            </b-row>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="4"
                            md="4"
                            xl="3"
                            class="d-flex align-content-center justify-content-left flex-wrap p-1"
                          >
                            <!--
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            block
                            variant="outline-secondary"
                          >
                            <feather-icon
                              icon="CornerDownLeftIcon"
                              class="mr-50"
                              size="16"
                            />
                            <span class="align-middle">GESTIONE RESI</span>
                          </b-button>
                          -->

                            <div v-if="listItem.ddt && listItem.orders && !parseInt(listItem.fg_consegnato)">
                              <b-button
                                v-if="((listItem.ddt.length === parseInt(listItem.n_ddt_firmati) && listItem.ddt.length === parseInt(listItem.n_ddt_in_arxivar))
                                  && (listItem.orders.filter(order => parseInt(order.con_conferma) === 1).length === listItem.orders.filter(order => parseInt(order.con_conferma) === 1 && parseInt(order.ordine_confermato) === 1).length)
                                )"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                block
                                variant="success"
                                class="mt-1"
                                @click="setDeliveryOk(listItem.n_consegna)"
                              >
                                <feather-icon
                                  icon="CheckIcon"
                                  class="mr-50"
                                  size="16"
                                />
                                <span class="align-middle">TUTTO OK! CHIUDI LA CONSEGNA</span>
                              </b-button>
                            </div>

                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              block
                              variant="secondary"
                              class="mt-1 d-block d-sm-none"
                              :to="{ name: 'delivery', params: { cargo: listItem.c_carico, delivery: listItem.n_consegna }}"
                            >
                              <span
                                :to="{ name: 'delivery', params: { cargo: listItem.c_carico, delivery: listItem.n_consegna }}"
                                class="align-middle"
                              >APRI</span>
                              <feather-icon
                                icon="ArrowRightIcon"
                                class="ml-50"
                                size="16"
                              />
                            </b-button>

                          </b-col>
                          <b-col
                            cols="12"
                            sm="1"

                            class="d-sm-flex d-none align-content-center justify-content-end flex-wrap"
                          >
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-secondary"
                              class="btn-icon btn-next d-flex align-content-center justify-content-center border-0 h-100"
                              :to="{ name: 'delivery', params: { cargo: listItem.c_carico, delivery: listItem.n_consegna }}"
                            >
                              <feather-icon
                                icon="ChevronRightIcon"
                                size="24"
                                class="align-self-center"
                              />
                            </b-button>
                          </b-col>

                        </b-row>
                        <b-row
                          v-if="listItem.notes && listItem.notes.length"
                          class="no-gutters notes-row"
                        >
                          <b-col
                            cols="12"
                          >
                            <app-collapse>
                              <app-collapse-item title="Note azienda">
                                <ul>
                                  <li
                                    v-for="(note, index) in listItem.notes"
                                    :key="index"
                                  >
                                    {{ note }}
                                  </li>
                                </ul>
                              </app-collapse-item>
                            </app-collapse>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </transition-group>
                  </b-list-group>
                </b-form-checkbox-group>
                <!-- END | Elenco consegne -->

                <div
                  v-if="parseInt(cargo.n_consegne_mappa) !==0 && (parseInt(cargo.n_consegne_mappa) === parseInt(cargo.n_consegne_mappa_chiuse))"
                  class="mt-1"
                >
                  <b-alert
                    variant="success"
                    show
                  >
                    <div class="alert-body">
                      <span><strong>Ottimo lavoro!</strong> Tutte le consegne sono state chiuse.</span>
                    </div>
                  </b-alert>
                </div>

              </b-col>
            </b-row>
          </b-card>
        </b-overlay>

        <b-card class="cargo-map-box">
          <b-row v-if="cargo && deliveries && parseInt(cargo.fg_stato_piano) > 5">
            <b-col
              cols="12"
              xl="12"
              class="d-flex justify-content-between flex-column"
            >
              <cargo-map :deliveries="deliveries" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- Modale ordini consegna -->
    <b-modal
      id="modal-orders"
      title="Ordini"
      centered
      size="lg"
      ok-only
      ok-variant="outline-secondary"
      ok-title="Chiudi"
    >
      <b-overlay
        :show="showOverlayOrders"
        variant="white"
      >
        <b-card-text class="modal-content-wrapper">
          <div v-if="cargo_delivery_orders">
            <div class="p-50">
              <p>In <span class="highlighted">GIALLO</span> sono evidenziati gli ordini aggiunti.</p>
            </div>
            <table class="mt-2 mt-xl-0 w-100 delivery-orders-table">
              <tr>
                <th class="p-50">
                  <span class="font-weight-bold">ORDINE</span>
                </th>
                <th class="p-50">
                  <span class="font-weight-bold">RIFERIMENTO</span>
                </th>
              </tr>
              <tr
                v-for="order in cargo_delivery_orders"
                :key="order.n_ordine"
                :class="{ 'agg' : parseInt(order.fg_minicommessa) }"
              >
                <td class="p-50">
                  {{ `${order.n_anno}-${order.c_numeratore}-${order.n_ordine}` }}
                </td>
                <td class="p-50">
                  {{ `${order.riferimento_cliente}` }}
                </td>
              </tr>
            </table>
          </div>
        </b-card-text>
      </b-overlay>
    </b-modal>

    <!-- Modale contatti utili -->
    <b-modal
      id="modal-contacts"
      title="Contatti utili"
      centered
      ok-only
      ok-variant="outline-secondary"
      ok-title="Chiudi"
    >
      <b-card-text
        v-if="cargo_delivery_data"
      >
        <div class="mt-1">
          <div
            v-if="cargo_delivery_data.telefono_capo_area"
            class="mb-1 pb-1 border-bottom"
          >
            <h4 class="mb-50">
              CAPO AREA
            </h4>
            <strong>{{ `${cargo_delivery_data.ds_capo_area}` }}</strong><br>
            {{ `Tel.: 041 5899${cargo_delivery_data.telefono_capo_area}` }}
          </div>
          <div
            v-if="cargo_delivery_data.ds_corrispondente_reclami"
            class="mb-1 pb-1 border-bottom"
          >
            <h4 class="mb-50">
              REFERENTE RECLAMI
            </h4>
            <strong>{{ `${cargo_delivery_data.ds_corrispondente_reclami}` }}</strong><br>
            {{ `Tel.: 041 5899${cargo_delivery_data.tel_corrispondente_reclami}` }}<br>
            {{ `E-mail: ${cargo_delivery_data.email_corrispondente_reclami}` }}
          </div>
          <div
            v-if="cargo_delivery_data.telefono_agente"
            class="mb-1 pb-1 border-bottom"
          >
            <h4 class="mb-50">
              AGENTE
            </h4>
            {{ `${cargo_delivery_data.ds_agente}` }}<br>
            {{ `Tel.: ${cargo_delivery_data.telefono_agente}` }}
          </div>
          <div
            v-if="cargo_delivery_data.client_contacts.length"
            class="mb-1 pb-1 border-bottom"
          >
            <h4 class="mb-50">
              CONTATTI DEL CLIENTE
            </h4>
            <div
              v-for="contact in cargo_delivery_data.client_contacts"
              :key="contact.PK"
              class="mb-50"
            >
              <div v-if="contact.n_telefono">
                <span v-if="contact.contatto"><strong>{{ `${contact.contatto}` }}</strong><br></span>
                Tel.: {{ contact.n_telefono }}
                <span v-if="contact.ds_raggr_tipi_doc">{{ `[${contact.ds_raggr_tipi_doc}]` }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-card-text>
    </b-modal>

  </div>

</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BPagination,
  BFormGroup,
  BAlert,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardText,
  BOverlay,
  BListGroupItem,
  BListGroup,
  VBTooltip,
  BModal,
  VBModal,
  BButton,
  BSidebar,
  BFormCheckbox,
  BFormCheckboxGroup,
  VBToggle,
  BCalendar,
  BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import VueBarcode from '@chenfengyuan/vue-barcode'
import { openApp } from '@mixins/ubsign'
import CargoMap from './CargoMap'
import appConfig from '../../../../../config.json'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardText,
    BOverlay,
    BListGroupItem,
    BListGroup,
    BAlert,
    VBTooltip,
    BModal,
    VBModal,
    BButton,
    VBToggle,
    BSidebar,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
    BFormCheckboxGroup,
    BCalendar,
    CargoMap,
    VueBarcode,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [openApp],
  data() {
    return {
      locale: 'it',
      dir: false,
      emptyTableStatus: 'loading',
      showOverlay: false,
      showOverlayList: false,
      showOverlayOrders: false,
      showOverlayDeliveryDate: false,
      showOverlayDDT: false,
      cargo: null,
      deliveries: null,
      ddt: [],
      cargo_delivery_orders: null,
      cargo_delivery_data: null,
      searchTerm: '',
      selectedDeliveries: [],
      allDeliveriesSelected: false,
      allDeliveriesIndeterminate: false,
      showClosedDeliveriesLocal: this.$store.state.cargo.cargo.showClosedDeliveries ?? false,
      control_pin_enabled: appConfig.control_pin_enabled ?? false,
    }
  },
  computed: {
    // eslint-disable-next-line consistent-return,vue/return-in-computed-property
    deliveriesID() {
      if (this.deliveries) {
        // eslint-disable-next-line camelcase
        return this.deliveries.map(({ n_consegna }) => n_consegna)
      }
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'Da configurare': 'light-dark',
        'In fase di configurazione': 'light-warning',
        'Configurazione conclusa': 'light-info',
        'Configurazione conclusa con avvisi': 'light-danger',
        'Configurazione confermata': 'light-success',
        'Configurazione rifiutata': 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    showClosedDeliveriesState() {
      return this.$store.state.cargo.cargo.showClosedDeliveries
    },
  },
  watch: {
    selectedDeliveries(newValue, oldValue) {
      if (newValue.length === 0) {
        this.allDeliveriesIndeterminate = false
        this.allDeliveriesSelected = false
      } else if (newValue.length === this.deliveriesID.length) {
        this.allDeliveriesIndeterminate = false
        this.allDeliveriesSelected = true
      } else {
        this.allDeliveriesIndeterminate = true
        this.allDeliveriesSelected = false
      }
    },
  },
  created() {
    this.loadCargo()
    this.loadDeliveries(true)
  },
  methods: {
    loadCargo() {
      this.loadDDT()
      this.showOverlay = true

      this.$http.get(`/cargo/info/${this.$route.params.cargo}`).then(res => {
        this.cargo = res.data.data.cargo
        this.showOverlay = false
      }).catch(() => {
        this.showOverlay = false
      })
    },
    loadDeliveries(removeOverlay) {
      this.showOverlayList = true
      return this.$http.get(`/cargo/in-progress/deliveries/${this.$route.params.cargo}`).then(res => {
        this.deliveries = res.data.data.deliveries
        if (removeOverlay) {
          this.showOverlayList = false
        }
      }).catch(error => {
        this.showOverlayList = false
      })
    },
    // recupero gli ordini della consegna selezionata
    getCargoDeliveryOrders(delivery) {
      this.cargo_delivery_orders = null
      this.showOverlayOrders = true

      this.$http.get(`/cargo/in-progress/delivery/${this.$route.params.cargo}/${delivery}/orders`).then(res => {
        this.cargo_delivery_orders = res.data.data.orders
        this.showOverlayOrders = false
      }).catch(error => {
        this.showOverlayOrders = false
      })
    },
    // recupero i dati della consegna selezionata
    getDeliveryData(delivery) {
      this.cargo_delivery_data = null
      this.cargo_delivery_data = this.deliveries.filter(el => el.n_consegna === delivery)[0]
    },
    ubsignDownloadDDT() {
      this.showOverlay = true
      const submitData = { c_carico: this.cargo.c_carico }
      this.$http.post('/ubsign/download', submitData).then(res => {
        this.showOverlay = false
        this.openApp(this.cargo.c_carico)
      }).catch(error => {
        this.showOverlay = false
      })
    },
    loadDDT() {
      this.showOverlayDDT = true

      this.$http.get(`/cargo/in-progress/${this.$route.params.cargo}/ddt-list`).then(res => {
        this.ddt = res.data.data.ddt
        this.showOverlayDDT = false
      }).catch(() => {
        this.showOverlayDDT = false
      })
    },
    setDeliveryOk(nScarico) {
      this.showOverlayList = true

      const obj = {
        c_carico: this.$route.params.cargo,
        n_scarico: nScarico,
      }
      // eslint-disable-next-line no-shadow
      return this.$http.post('/cargo/delivery/set-ok', obj).then(res => {
        this.showOverlayList = false
        this.loadDeliveries(true)
      }).catch(() => {
        this.showOverlayList = false
      })
    },
    setShowClosedDeliveryState(show) {
      store.commit('cargo/SET_SHOW_CLOSED_DELIVERIES', show)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/core.scss";
@import "~/src/assets/scss/variables/_variables.scss";

.page-cargo {

  .cargo-info-box {
    min-height: 260px;

    .barcode {
      svg {
        max-width: 100% !important;
      }
    }
  }

  .list-group-item.delivery {
    /* transition: all 0; */
    padding: 0;
    margin-bottom: 24px;

    &:hover {
      background: #ffffff !important;
    }

    &.delivery {
      border: 1px solid #cccccc;

      .address {
        line-height: 1;
        margin-bottom: 12px;
      }
    }

    &.important {
      //border: 2px solid green !important;
    }

    .delivery-checkbox-wrapper {
      line-height: 1;
      margin-top: 3px;
    }

    .drag-wrapper {

      .drag {

      }
    }
  }

  .notes-row {

    .collapse-default {
      .card {
        .card-header {
          border-radius: 0;
          background: $highlighted-yellow;
          color: $dark;
          padding: 0.5rem 2.8rem 0.5rem 1rem;

          .collapse-title {
            text-transform: uppercase;
            font-size: 0.85rem;
          }

          &:before {
            content: "";
            position: absolute;
            top: -5px;
            left: 50%;
            display: block;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            background: #ffffff;
          }

        }

        .card-body {
          background: #f3f3f3;
          padding-top: 1rem;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .notes-row {
      .collapse-default {
        .card {
          .card-header {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }

  .badge.badge-up {
    right: -13px;
  }

  .modal-content-wrapper {
    min-height: 200px;
  }

  .b-calendar {
    .b-calendar-inner {
      min-width: 230px;
    }
  }

  .btn-next {
    background: #ededed;
    border-radius: 0 !important;

    &.border-0 {
      border: none !important;
    }
  }

  @include media-breakpoint-only(sm) {
    .delivery {
      .btn {
        padding: 5px 5px;
        font-size: 12px;
      }
    }
  }
}

.delivery-orders-table {
  tr {
    &.agg {
      background: $highlighted-yellow !important;
    }
  }
}

</style>
