<template>
  <div>
    <cargo-info/>
  </div>

</template>

<script>
import CargoInfo from './components/CargoInfo.vue'

export default {
  components: {
    CargoInfo,
  },
}
</script>

<style lang="scss">

</style>
